<script>
/* stampa data di modifica, creazione, utente e autore di un item */
export default {
  props: {
    mdate: {
      type: String
    },
    muser: {
      type: String
    }
  }
}
</script>

<template>
  <div class="caption grey--text text--darken-1">
    <div v-if="muser && mdate">
      <strong>Ultima modifica</strong> {{ muser }}, {{ mdate }}
    </div>
  </div>
</template>
