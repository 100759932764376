<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Componenti" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getComponenti"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!componenti.length">
          Nessun componente trovato
        </v-alert>
        <v-list class="mt-2" v-if="componenti.length">
          <template v-for="(componente, index) in componenti">
            <v-list-tile @click.stop="openCompDialog(componente)" :key="'tile-'+componente._id">
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize" v-text="componente.descrizione" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon @click.stop="deleteComponente(componente)" color="error">delete</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="componente._id" v-if="index < componenti.length - 1" />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-dialog
        v-model="showComp"
        transition="dialog-bottom-transition"
        :overlay="false"
        width="500">
        <v-card>
          <v-toolbar class="grey lighten-3" card>
            <v-toolbar-title>
              <span v-if="componente._id">{{componente.descrizione}}</span>
              <span v-if="!componente._id">Nuovo componente</span>
              <item-updated
                v-if="componente._id"
                :mdate="componente.modifica"
                :muser="componente.utente" />
              </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.stop="showComp=false"><v-icon>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-card>
              <v-form ref="formComponente" v-model="validFormComponente">
                <v-container fluid grid-list-xl>
                  <v-layout row wrap>
                    <v-flex>
                      <v-text-field
                        label="Descrizione*"
                        v-model="componente.descrizione"
                        :rules="[$rules.required, $rules.maxlength(200)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        class="ml-0"
                        color="primary"
                        :disabled="!validFormComponente"
                        @click.native="save()">
                        Salva
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openCompDialog()">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo componente</span>
      </v-tooltip>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import modalConfirm from '@/components/common/ModalConfirm'
import itemUpdated from '@/components/common/ItemUpdated'
import _clone from 'lodash/clone'

export default {
  data: () => ({
    componenti: [],
    componente: {},
    search: null,
    pages: 0,
    page: 1,
    showComp: false,
    paginate: 1000,
    validFormComponente: false
  }),
  components: {
    itemUpdated,
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getComponenti () {
      this.$plsqlReadAll('componente', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.componenti = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    deleteComponente (componente) {
      this.componente = componente
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare il componente <strong>' + componente.descrizione + '</strong>.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.remove,
        modalObj: componente
      })
    },
    remove () {
      this.$plsqlDelete('componente', this.componente._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Componente eliminato'
          })
          this.$refs.confirm.hide()
          this.getComponenti()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il componente perché usato' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.$refs.confirm.hide()
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getComponenti()
    },
    save () {
      if (this.$refs.formComponente.validate()) {
        this.$plsqlSave('componente', this.componente)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Componente salvato'
            })
            this.showComp = false
            this.getComponenti()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio componente' })
          })
      }
    },
    openCompDialog (componente) {
      this.componente = componente ? _clone(componente) : { }
      this.showComp = true
      if (!componente) this.$refs.formComponente.reset()
    }
  },
  mounted () {
    this.getComponenti()
  }
}
</script>
