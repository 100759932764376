<template>
  <v-dialog v-model="visualizza" persistent max-width="250" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="headline">{{modalTitle}}</v-card-title>
      <v-card-text v-html="modalText"></v-card-text>
      <v-card-actions>
        <v-btn id="modalConfirmAnnulla" @click.native="functionAnnulla()">{{modalButtonAnnulla}}</v-btn>
        <v-btn color="error" id="modalConfirmOk" @click.native="functionOk()">{{modalButtonOk}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* USO :
  html :
    <modal-confirm ref="confirm" />
  js :
  import modalConfirm from '@/components/common/ModalConfirm'

  components: {
    'modal-confirm': modalConfirm
  }

  this.$refs.confirm.show({
    modalTitle: 'Conferma eliminazione',
    modalText: 'Sei sicuro di voler eliminare i bancali....?',
    modalButtonOk: 'Elimina',
    modalButtonAnnulla: 'Annulla',
    modalFunctionAnnulla: function (banc) { },
    modalFunctionOk: function (banc) { },
    modalObj: b
  })

*/

const comp = {
  data: () => ({
    modalProperties: Object,
    modalTitle: '',
    modalText: '',
    modalButtonOk: '',
    modalButtonAnnulla: '',
    modalFunctionOk: {},
    modalFunctionAnnulla: {},
    modalObj: {},
    visualizza: false
  }),
  methods: {
    functionAnnulla () {
      // console.log('modal Annulla')
      if (this.modalFunctionAnnulla) {
        this.modalFunctionAnnulla(this.modalObj)
      }
      this.visualizza = false
    },
    functionOk () {
      // console.log('modal Ok')
      if (this.modalFunctionOk) {
        this.modalFunctionOk(this.modalObj)
      }
      this.visualizza = false
    },
    show (p) {
      this.modalProperties = p
      this.init()
      this.visualizza = true
    },
    hide () {
      this.visualizza = false
    },
    status () {
      return this.visualizza
    },
    init () {
      this.modalTitle = this.modalProperties.modalTitle ? this.modalProperties.modalTitle : 'Conferma'
      this.modalText = this.modalProperties.modalText
      this.modalButtonOk = this.modalProperties.modalButtonOk ? this.modalProperties.modalButtonOk : 'Ok'
      this.modalButtonAnnulla = this.modalProperties.modalButtonAnnulla ? this.modalProperties.modalButtonAnnulla : 'Annulla'
      this.modalFunctionOk = this.modalProperties.modalFunctionOk
      this.modalFunctionAnnulla = this.modalProperties.modalFunctionAnnulla
      this.modalObj = this.modalProperties.modalObj
    }
  }
}

export default comp
</script>
